import React from "react"

import Layout from "../components/Layouts/secondaryPage"
import ChoiceRedisign from "../components/ChoiceRedesign"
import { NewHeader } from "../components/Headers/NewHeader"

export default function ChoicePage() {
  return (
    <div className="bg-wrapper">
      <Layout>
        <NewHeader noRightSection noShadow={false} />
        <ChoiceRedisign />
      </Layout>
    </div>
  )
}
